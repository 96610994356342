<template>
  <svg
    class="LogoBase"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="name"
    role="presentation">
    <component
      :is="computedLogo"
      v-bind="{ color }"
    />
  </svg>
</template>


<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'LogoBase',

  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedLogo() {
      const logoName = this.name;
      return defineAsyncComponent(() => import(`@/components/logo/${logoName}.vue`));
    },
  },
};
</script>


<style scoped>
.LogoBase {
  display: block;
  margin: 0 auto;
}
</style>
